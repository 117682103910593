import { createRouter, createWebHistory } from 'vue-router';
import { Hub } from 'aws-amplify';
import { routes } from './routes';
import { getUser } from '@/utils/helper';
import store from '@/store/store';
let user;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

Hub.listen('auth', async (data) => {
  if (data.payload.event === 'signOut') {
    user = null;
    store.dispatch('setUserData', null);
    router.push({ path: '/login' });
  } else if (data.payload.event === 'signIn') {
    user = await getUser();
    store.dispatch('fetchUserData');
    router.push({ path: '/' });
  }
});

router.beforeResolve(async (to, from, next) => {
  user = await getUser();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!user) {
      store.dispatch('setUserData', null);
      return next({
        path: '/login',
      });
    }
    return next();
  } else if (user && to.path == '/login') {
    return next({
      path: '/',
    });
  }
  return next();
});
export default router;
