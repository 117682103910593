const publicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: { requiresAuth: false },
  },
];

const privateRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: true, isGeneralLayout: true },
  },

  // {
  //   path: '/contract',
  //   name: 'contract',
  //   component: () => import('../views/ContractView.vue'),
  //   meta: { requiresAuth: true, isGeneralLayout: true },
  // },
];

export const routes = [...publicRoutes, ...privateRoutes];
