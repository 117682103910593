<template>
  <div class="">
    <div class="d-flex flex-column ga-3 sidebar">
      <div
        class="d-flex align-items-center ga-2"
        v-for="(route, index) in navItems"
        :key="index"
      >
        <router-link
          :to="route.path"
          class="d-flex align-items-center ga-2 p-2 sidebar-items w-100"
        >
          <img
            alt="Einstellungen"
            draggable="false"
            :src="require(`../../assets/icons/${route.label}.svg`)"
          />
          <span class="category-name">{{ route.label }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        {
          name: 'home',
          icon: 'fa fa-home',
          label: 'Vertragssuche',
          path: '/',
          slug: '/',
        },

        // {
        //   name: 'marketdata',
        //   icon: 'fa fa-th-large',
        //   label: 'Marktdaten',
        //   path: '/marketdata',
        // },
      ],
    };
  },
};
</script>

<style>
.sidebar {
  padding-top: 100px;
}
.sidebar .sidebar-items:hover:not(.router-link-active) {
  border-radius: 10px;
  cursor: pointer;
  background-color: #e4e6e3;
}
.sidebar .sidebar-items {
  text-decoration: none;
}
.category-name {
  font-weight: 400;
  font-size: 18px;
  font-family: 'Almarai', sans-serif;
  color: #000000;
}

.sidebar .router-link-active {
  background-color: #e4e6e3;
  border-radius: 10px;
}
</style>
