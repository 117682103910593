import moment from "moment-timezone";
import { Auth } from "aws-amplify";

export const debounce = function (
  func,
  timeout = 500,
  context,
  contextVariable = "debounceTimer"
) {
  if (context[contextVariable]) {
    clearTimeout(context[contextVariable]);
  }
  context[contextVariable] = setTimeout(() => {
    func.apply(this);
  }, timeout);
};

export const formatDate = (dateString) => {
  const parts = dateString.split(".");
  const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
  return date.toLocaleDateString("de-DE", {
    day: "numeric",
    month: "short",
    year: "2-digit",
  });
};

export const getFormattedDates = (dateString) => {
  const dates = dateString.split("-");
  return dates.map((el) => formatDate(el.trim()));
};

// export const formatNumber = function (numberToFormat, numberOfDecimals = 2) {
//   if (
//     typeof numberToFormat !== 'number' ||
//     isNaN(numberToFormat) ||
//     typeof numberOfDecimals !== 'number' ||
//     isNaN(numberOfDecimals)
//   ) {
//     return 'Invalid arguments';
//   }
//   let roundedNumber = numberToFormat;
//   if (!Number.isInteger(numberToFormat)) {
//     roundedNumber = parseFloat(
//       numberToFormat.toFixed(numberOfDecimals)
//     ).toLocaleString();
//     let decimals = roundedNumber.split('.');
//     roundedNumber = decimals[0].replaceAll(',', '.') + ',' + decimals[1];
//   } else {
//     roundedNumber = roundedNumber.toLocaleString().replaceAll(',', '.');
//   }
//   return roundedNumber;
// };

export const formatNumber = function (numberToFormat, numberOfDecimals = 3) {
  if (
    typeof numberToFormat !== "number" ||
    isNaN(numberToFormat) ||
    typeof numberOfDecimals !== "number" ||
    isNaN(numberOfDecimals)
  ) {
    return numberToFormat;
  }
  let roundedNumber = parseFloat(numberToFormat.toFixed(numberOfDecimals));
  return roundedNumber.toLocaleString("de-DE");
};

export const timestampToCETConverter = (timestamp, dateFormat = null) => {
  const date = moment.tz(timestamp, "Europe/Berlin").locale("de");

  if (dateFormat) {
    return date.format(dateFormat);
  }

  return date.format().split("+")[0];
};

export const getUser = async () => {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        return data;
      }
    })
    .catch(() => {
      return null;
    });
};
