import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "@aws-amplify/ui-vue/styles.css";
import { Amplify } from "aws-amplify";
import amplifyconfig from "../aws-exports";
Amplify.configure(amplifyconfig);
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import vuetify from "./vuetify/index";

createApp(App).use(store).use(router).use(vuetify).mount("#app");
