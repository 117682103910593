<template>
  <div class="site-loader-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="
        margin: auto;
        background: none;
        display: block;
        shape-rendering: auto;
      "
      width="197px"
      height="197px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="site-loader"
    >
      <g transform="rotate(0 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.8608695652173912s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(3.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.8521739130434781s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(7.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.8434782608695651s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(10.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.834782608695652s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(14.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.826086956521739s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(18 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.817391304347826s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(21.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.8086956521739129s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(25.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7999999999999999s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(28.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7913043478260868s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(32.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7826086956521738s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(36 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7739130434782607s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(39.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7652173913043477s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(43.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7565217391304346s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(46.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7478260869565216s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(50.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7391304347826086s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(54 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7304347826086955s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(57.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7217391304347825s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(61.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7130434782608694s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(64.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.7043478260869565s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(68.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6956521739130433s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(72 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6869565217391304s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(75.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6782608695652174s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(79.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6695652173913043s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(82.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6608695652173913s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(86.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6521739130434782s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6434782608695652s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(93.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6347826086956521s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(97.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6260869565217391s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(100.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.617391304347826s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(104.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.608695652173913s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(108 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.6s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(111.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5913043478260869s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(115.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5826086956521739s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(118.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5739130434782608s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(122.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5652173913043478s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(126 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5565217391304347s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(129.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5478260869565217s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(133.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5391304347826086s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(136.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5304347826086956s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(140.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5217391304347826s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(144 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5130434782608695s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(147.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.5043478260869565s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(151.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.4956521739130434s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(154.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.4869565217391304s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(158.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.47826086956521735s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(162 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.4695652173913043s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(165.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.46086956521739125s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(169.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.4521739130434782s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(172.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.44347826086956516s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(176.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.4347826086956521s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.42608695652173906s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(183.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.417391304347826s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(187.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.408695652173913s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(190.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.39999999999999997s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(194.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3913043478260869s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(198 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.38260869565217387s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(201.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3739130434782608s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(205.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.36521739130434777s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(208.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3565217391304347s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(212.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3478260869565217s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(216 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3391304347826087s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(219.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.33043478260869563s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(223.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3217391304347826s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(226.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.31304347826086953s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(230.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.3043478260869565s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(234 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.29565217391304344s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(237.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.2869565217391304s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(241.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.27826086956521734s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(244.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.2695652173913043s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(248.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.2608695652173913s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(252 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.25217391304347825s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(255.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.2434782608695652s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(259.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.23478260869565215s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(262.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.2260869565217391s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(266.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.21739130434782605s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.208695652173913s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(273.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.19999999999999998s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(277.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.19130434782608693s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(280.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.18260869565217389s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(284.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.17391304347826084s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(288 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.16521739130434782s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(291.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.15652173913043477s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(295.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.14782608695652172s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(298.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.13913043478260867s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(302.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.13043478260869565s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(306 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.1217391304347826s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(309.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.11304347826086955s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(313.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.1043478260869565s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(316.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.09565217391304347s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(320.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.08695652173913042s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(324 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.07826086956521738s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(327.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.06956521739130433s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(331.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.0608695652173913s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(334.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.05217391304347825s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(338.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.04347826086956521s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(342 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.03478260869565217s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(345.6 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.026086956521739126s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(349.2 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.017391304347826084s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(352.8 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="-0.008695652173913042s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(356.4 50 50)">
        <rect
          x="49.5"
          y="29.5"
          rx="0"
          ry="0"
          width="1"
          height="1"
          fill="#24bf05"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.8695652173913042s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "SiteLoader",
};
</script>
<style scoped>
.site-loader-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.site-loader-container .site-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
