import { getUser } from '@/utils/helper';
import { createStore } from 'vuex';
/* eslint-disable */
export default createStore({
  state: {
    customerData: [],
    userData: null,
    isLoading: false,
    adminUsersList: null,
  },
  mutations: {
    SET_USER_DATA(state, data) {
      state.userData = data;
    },
    SET_LOADING(state, data) {
      state.isLoading = data;
    },
  },
  getters: {
    getUserData(state) {
      return state.userData;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getAdminUsers(state) {
      return state.adminUsersList;
    },
  },
  actions: {
    async setUserData({ commit }, data) {
      commit('SET_USER_DATA', data);
    },
    async fetchUserData({ commit, dispatch, state }) {
      commit('SET_LOADING', true);
      const user = await getUser();
      if (user) {
        dispatch('setUserData', user);
        setTimeout(() => {
          commit('SET_LOADING', false);
        }, 2000);
      } else {
        commit('SET_LOADING', false);
        dispatch('setUserData', null);
      }
    },

    // ADMIN ACTIONS
  },
  modules: {},
});
