const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_7ZrNwJamR',
  aws_user_pools_web_client_id: '35q0epfaqigt1tco9vrgimlj4',
};

export default awsmobile;

// userPoolId: eu-central-1_7ZrNwJamR
// appClientId: 35q0epfaqigt1tco9vrgimlj4
