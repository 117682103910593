<template>
  <header class="py-4 border-bottom">
    <div class="custom-container">
      <div class="d-flex justify-content-between align-items-center">
        <img
          class="d-flex"
          alt="Team logo"
          draggable="false"
          src="../../assets/icons/Team.svg"
        />

        <div class="d-flex align-items-center ga-4">
          <div class="d-flex align-items-center ga-4">
            <v-menu :close-on-content-click="false" v-model="menu">
              <template v-slot:activator="{ props }">
                <div
                  class="d-flex align-items-center ga-2 cursor-pointer"
                  text
                  flat
                  dark
                  v-bind="props"
                >
                  <span class="navbar-username">{{
                    getUserName() || '--'
                  }}</span>
                  <i v-if="menu" class="fa-solid fa-chevron-up"></i>
                  <i v-else class="fa-solid fa-chevron-down"></i>
                </div>
              </template>
              <v-list class="py-0 user-profile-dropdown">
                <div
                  class="pa-5 user-detail-wrap d-flex ga-15 align-items-center pb-1"
                >
                  <div class="user-details">
                    <div class="user-name">{{ getUserEmail() || '--' }}</div>
                  </div>
                </div>
                <div class="user-detail-option-list pa-2">
                  <ul>
                    <li @click="Auth.signOut()">
                      <button class="d-flex align-items-center ga-3 px-2">
                        <img
                          alt="Ausloggen"
                          draggable="false"
                          src="../../assets/icons/Logout.svg"
                        />
                        <span>Ausloggen</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { Auth } from 'aws-amplify';
import { mapGetters } from 'vuex';
</script>
<script>
export default {
  data() {
    return {
      menu: false,
    };
  },
  /* eslint-disable */
  methods: {
    getUserEmail() {
      if (this.getUserData && this.getUserData.hasOwnProperty('attributes')) {
        return this.getUserData.attributes.email;
      } else {
        return null;
      }
    },
    getUserName() {
      try {
        const email = this.getUserEmail();
        if (email) {
          const arr = email?.split('@');
          if (arr && arr.length >= 1) {
            return arr[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
  },
  computed: {
    ...mapGetters(['getUserData']),
  },
};
</script>
<style scoped>
.user-shortname {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Teambold';
}
.navbar-username {
  font-size: 16px;
  font-weight: 400;
  color: #000000 !important;
  font-family: 'Teammedium';
  text-transform: none;
}

:deep(.v-btn) {
  box-shadow: none;
  outline: none !important;
}
:deep(.gray-btn) {
  background: #edefec;
  color: #000;
}
.user-details .user-name {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  font-family: 'Teammedium';
}

.user-details .user-id {
  color: #656565;
  font-weight: 400;
  font-size: 14px;
}
.user-detail-option-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.user-detail-option-list {
  border-bottom: 1px solid #ebeaea;
}

.user-detail-option-list li {
  padding: 5px 0;
}

.user-detail-option-list ul li:hover {
  border-radius: 10px;
  cursor: pointer;
  background-color: #d3d3d3;
}

.user-detail-option-list li .hilfe-icon {
  color: #656565 !important;
}
.user-detail-option-list li span {
  font-size: 14px;
  color: #656565;
  font-weight: 400;
  font-family: 'Teammedium';
}
.user-profile-dropdown {
  border-radius: 19px !important;
  box-shadow: 0 0 30px #0000002b !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.new-user {
  color: #656565;
  font-family: 'Teammedium';
}
.plus-icon {
  color: #656565 !important;
}
.cursor-pointer {
  cursor: pointer;
}

.detail-btn:focus {
  outline: none !important;
}
</style>
