<template>
  <template v-if="isGeneralLayout">
    <HeaderComp />
    <div class="general-layout">
      <div class="general-layout-wrap custom-container">
        <div class="general-layout-left">
          <SideBar />
        </div>
        <div class="general-layout-loader-container" v-if="getIsLoading">
          <SiteLoader />
        </div>
        <div class="general-layout-right pl-16" v-else>
          <router-view />
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="public-layout">
      <router-view />
    </div>
  </template>
</template>
<script>
import SideBar from "../layout/SideBar.vue";
import HeaderComp from "../layout/HeaderComp.vue";
import SiteLoader from "../SiteLoader.vue";
import { mapGetters } from "vuex";
export default {
  name: "GeneralLayout",
  components: {
    SideBar,
    HeaderComp,
    SiteLoader,
  },
  computed: {
    ...mapGetters(["getIsLoading"]),
    isGeneralLayout() {
      return this.$route.meta?.isGeneralLayout;
    },
  },
};
</script>
<style scoped>
.general-layout {
  height: calc(100dvh - 82px);
  overflow: auto;
}
.general-layout-wrap {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
  /* padding-top: 100px; */
}

.general-layout-wrap .general-layout-left {
  width: 190px;
  position: sticky;
  top: 0;
  height: 100%;
}

.general-layout-wrap .general-layout-right {
  width: calc(100% - 190px);
  padding-top: 100px;
}

.general-layout .general-layout-wrap .general-layout-loader-container {
  width: calc(100% - 190px);
}
</style>
