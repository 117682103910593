<template>
  <div>
    <v-app>
      <v-main>
        <SiteLoader v-if="isLoading" />
        <GeneralLayout v-if="!isLoading" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import './style.css';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';
import GeneralLayout from './components/layout/GeneralLayout.vue';
import SiteLoader from './components/SiteLoader.vue';
export default {
  name: 'AppLayout',
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    this.$store.dispatch('fetchUserData');
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  async mounted() {
    // Set I18n Translations
    //de-DE
    I18n.putVocabulariesForLanguage('de', {
      [translations.SIGN_IN_HEADER_TEXT]: 'Zugangsdaten eingeben',
      [translations.SIGN_IN_ACTION]: 'Anmelden',
      [translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
      [translations.RESET_PASSWORD_TEXT]: 'Passwort zurücksetzen',
      [translations.RESET_YOUR_PASSWORD]: 'Passwort zurücksetzen',
      [translations.NEW_PASSWORD_LABEL]: 'Neues Passwort *',
      [translations.PASSWORD_LABEL]: 'Passwort *',
      [translations.PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
      [translations.USERNAME_LABEL]: 'E-Mail-Adresse *',
      [translations.USERNAME_PLACEHOLDER]: 'E-Mail-Adresse eingeben',
      [translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Passwort',
      [translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'E-Mail-Adresse',
      [translations.SIGN_OUT]: 'Abmelden',
      [translations.FAMILY_NAME_LABEL]: 'Nachname',
      [translations.NAME_LABEL]: 'Vorname',
      'Reset Password': 'Passwort zurücksetzen',
      Password: 'Passwort',
      'Forget your password? ': 'Passwort vergessen?',
      'Enter your Username': 'E-Mail-Adresse eingeben',
      'New Password': 'Neues Passwort',
      'Resend Code': 'Code erneut senden',
      'Back to Sign In': 'Zurück',
      'Send Code': 'Code senden',
      'Username cannot be empty': 'Bitte geben Sie einen Benutzernamen ein',
      'Incorrect username or password.': 'Benutzername oder Passwort falsch.',
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
        'Fehler im Benutzername. Bitte stellen Sie sicher, dass sich keine Leerzeichen am Anfang oder Ende befinden.',
      'CUSTOM_AUTH is not enabled for the client.':
        'CUSTOM_AUTH ist für den Client nicht aktiviert.',
      "PreAuthentication failed with error Das Attribute 'custom:updatedAt' fehlt. Bitte kontaktieren Sie ihren Administrator..":
        'Die Vorauthentifizierung ist mit einem Fehler fehlgeschlagen. Das Attribut „custom:updatedAt“ fehlt. Bitte kontaktieren Sie Ihren Administrator..',
    });
    I18n.putVocabularies(translations);
    I18n.setLanguage('de');
  },
  components: { GeneralLayout, SiteLoader },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
